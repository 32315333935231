var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
!function (e, t) {
  exports = t();
}(exports, function () {
  "use strict";

  return function (e, t) {
    t.prototype.weekday = function (e) {
      var t = this.$locale().weekStart || 0,
        i = (this || _global).$W,
        n = (i < t ? i + 7 : i) - t;
      return this.$utils().u(e) ? n : this.subtract(n, "day").add(e, "day");
    };
  };
});
export default exports;